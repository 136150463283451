<template>
    <div>
        <b-row class="justify-content-center">
            <b-col cols="auto">
                <div
                    class="card rounded-pill animate__animated animate__backInDown"
                >
                    <div
                        class="d-flex justify-content-center align-items-center p-2"
                    >
                        <div class="text-center mx-2">
                            <p
                                class="text-success font-large-2 font-weight-bold text-shadow"
                            >
                                {{ indicadores.activos }}
                            </p>
                            <span>Activos</span>
                        </div>
                        <div>
                            <span class="font-large-2">/</span>
                        </div>
                        <div class="text-center mx-2">
                            <p
                                class="text-danger font-large-2 font-weight-bold text-shadow"
                            >
                                {{ indicadores.inactivos }}
                            </p>
                            <span>Inactivos</span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-title class="pt-2 pl-2"
                        >Listado de pacientes</b-card-title
                    >
                    <tabla-general
                        :columns="tableColumns"
                        :items="listaPacientes"
                        button-activated
                        :loading="loading"
                    >
                        <template v-slot:boton>
                            <b-button
                                variant="success"
                                class="p-75"
                                @click="abrirSidebarCrearPaciente"
                            >
                                Crear
                            </b-button>
                        </template>
                        <template v-slot:cell(nombre)="data">
                            <div
                                @click="
                                    $router.push({
                                        name: 'ver-paciente',
                                        params: { id: data.item.id },
                                    })
                                "
                            >
                                {{ data.item.nombre }}
                            </div>
                        </template>
                        <template v-slot:cell(genero)="data">
                            <div>
                                {{ getGenero(data.item.genero) }}
                            </div>
                        </template>
                        <template v-slot:cell(fecha_nacimiento)="data">
                            <div>
                                {{ getEdad(data.item.fecha_nacimiento) }}
                            </div>
                        </template>
                        <template v-slot:cell(opciones)="data">
                            <div>
                                <b-dropdown variant="link" no-caret>
                                    <template v-slot:button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                        ></feather-icon>
                                    </template>
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="EyeIcon"
                                        ></feather-icon>
                                        Ver</b-dropdown-item
                                    >
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="EditIcon"
                                        ></feather-icon>
                                        Editar</b-dropdown-item
                                    >
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="EyeOffIcon"
                                        ></feather-icon>
                                        Inactivar</b-dropdown-item
                                    >
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="Trash2Icon"
                                        ></feather-icon>
                                        Borrar</b-dropdown-item
                                    >
                                </b-dropdown>
                            </div>
                        </template>
                    </tabla-general>
                </b-card>
            </b-col>
        </b-row>

        <!-- sidebars  -->
        <sidebar-crear-paciente
            ref="refsidebarCrearPaciente"
            @update="listarPacientes"
        ></sidebar-crear-paciente>
    </div>
</template>
<script>
import dayjs from "dayjs";
import Pacientes from "@/services/pacientes";
import { ref, onMounted, reactive } from "@vue/composition-api";
export default {
    emits: ["update"],
    components: {
        SidebarCrearPaciente: () =>
            import("./sidebars/SidebarCrearPaciente.vue"),
    },
    setup(props, context) {
        const loading = ref(false);

        const tableColumns = [
            { key: "identificacion", label: "Identificación" },
            { key: "nombre", sortable: true },
            { key: "apellido", sortable: true },
            {
                key: "fecha_nacimiento",
                label: "Edad",
                sortable: true,
                class: "text-center",
            },
            {
                key: "entidad",
                sortable: true,
                class: "text-center",
            },
            { key: "genero", sortable: true, class: "text-center" },
            { key: "opciones" },
        ];

        const listaPacientes = ref([]);
        const indicadores = ref({});

        function abrirSidebarCrearPaciente() {
            context.refs.refsidebarCrearPaciente.toggle();
        }

        async function listarPacientes() {
            try {
                loading.value = true;
                const { data } = await Pacientes.listarPacientes();

                listaPacientes.value = data.pacientes;
                indicadores.value = data.indicador;
            } catch (error) {
                context.root.catch(error);
            } finally {
                loading.value = false;
            }
        }

        onMounted(() => {
            listarPacientes();
        });

        return {
            tableColumns,
            abrirSidebarCrearPaciente,
            listaPacientes,
            indicadores,
            loading,
            listarPacientes,
        };
    },
};
</script>
