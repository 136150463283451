import axiosIns from "@/libs/axios";

const api = "/pacientes";

export default {
    listarPacientes: () => axiosIns.get(`${api}`),
    crearPaciente: (paciente) => axiosIns.post(`${api}`, paciente),
    editarPaciente: (paciente) =>
        axiosIns.put(`${api}/${paciente.id}`, paciente),
    //ver paciente
    verPaciente: (idPaciente) => axiosIns.get(`${api}/${idPaciente}`),
    listarEntidadesPaciente: (idPaciente) =>
        axiosIns.get(`${api}/${idPaciente}/entidades`),
    // dispositivos
    listarDispositivosPaciente: (idPaciente) =>
        axiosIns.get(`${api}/${idPaciente}/dispositivos`),
    vincularDispositivo: (params) =>
        axiosIns.post(`${api}/dispositivo`, params),
    retirarDispositivo: (idUserDispositivo) =>
        axiosIns.put(`${api}/dispositivo/${idUserDispositivo}/retirar`),

    // asistencias
    listarAsistenciasPacientes: (idPaciente) =>
        axiosIns.get(`${api}/${idPaciente}/asistencias`),
};
